import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Image from '../components/Image';
import SEO from '../components/SEO';

const ResourcesPage: FunctionComponent = () => (
  <Layout>
    <SEO title="Ever Forward Resources" />

    <div className="hero">
      <Image className="hero__image left-mobile" fileName="resources-hero.png" />

      <div className="hero__overlay" />

      <div className="hero__content">
        <div className="wrapper">
          <h1>Coach. Podcaster. COACHCASTER.</h1>

          <p className="hero__blurb">Health coach turned podcaster, Chase interviews industry leaders in fitness nutrition and mindset. Messages to help you live a life EVER FORWARD.</p>

          <h2>Chase Chewning, MS, ACE CHC</h2>

          <div className="actions">
            <a
              href="/https://podcasts.apple.com/gb/podcast/ever-forward-radio/id1195967486"
              className="button button--blue"
              target="_blank"
              rel="noreferrer noopener"
            >
              Listen on Apple Podcasts
            </a>

            <p>or</p>

            <Link to="/contact" className="button button--white">Contact Me</Link>
          </div>
        </div>
      </div>
    </div>

    <section className="container" style={{ paddingBottom: 50 }}>
      <h2 className="text-center section-header">Resources</h2>

      <p className="text-center" style={{ marginTop: 50 }}>Along my journey I have discovered incredible tools and resources that have help me live a life EVER FORWARD. Over the years I have had the privilege of connecting with countless like-minded people, many of whom are much more intelligent than myself, and found incredible value in other resources. Content from my peers, books, podcasts, products and services that have helped me live a life Ever Forward can be found below.</p>

      <div className="grid">
        <div className="grid__col--xs-12 grid__col--sm-6 text-center">
          <h2>Podcasts</h2>

          <ul className="resource-list">
            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/the-model-health-show/id640246578?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                The Model Health Show
              </a>
            </li>

            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/mind-pump-raw-fitness-truth/id954100822?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                Mind Pump
              </a>
            </li>

            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/wellness-force-radio/id1009470193?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                Wellness Force Radio
              </a>
            </li>

            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/the-school-of-greatness-with-lewis-howes/id596047499?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                The School of Greatness
              </a>
            </li>

            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/aubrey-marcus-podcast/id521945322?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                Aubrey Marcus
              </a>
            </li>

            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/the-one-thing/id1191482456?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                The One Thing
              </a>
            </li>

            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/entrepreneurs-on-fire/id564001633?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                Entrepreneurs on Fire
              </a>
            </li>

            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/the-feed-the-official-libsyn-podcast/id668413144?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                The Feed
              </a>
            </li>

            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/the-tim-ferriss-show/id863897795?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                The Tim Ferriss Show
              </a>
            </li>

            <li className="resource-list__elem">
              <a
                href="https://itunes.apple.com/us/podcast/how-i-built-this-with-guy-raz/id1150510297?mt=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                How I Built This
              </a>
            </li>
          </ul>
        </div>

        <div className="grid__col--xs-12 grid__col--sm-6 text-center">
          <h2>Books - Personal Development</h2>

          <ul className="resource-list">
            <li className="resource-list__elem">
              <a href="https://amzn.to/2FEemCO" target="_blank" rel="noreferrer noopener">
                Ego is the Enemy
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2FIDOam" target="_blank" rel="noreferrer noopener">
                The Obstacle is the Way
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2TUEXzb" target="_blank" rel="noreferrer noopener">
                The Daily Stoic
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2DfWJr8" target="_blank" rel="noreferrer noopener">
                12 Rules for Life
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2T0v0jQ" target="_blank" rel="noreferrer noopener">
                Tribe of Mentors
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2VZoagh" target="_blank" rel="noreferrer noopener">
                King, Warrior, Magician, Lover
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2T0SDc7" target="_blank" rel="noreferrer noopener">
                The 5 Love Languages
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2DhjzPa" target="_blank" rel="noreferrer noopener">
                Start with Why
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2swl9GL" target="_blank" rel="noreferrer noopener">
                How to Win Friends and Influence People
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2CpkawC" target="_blank" rel="noreferrer noopener">
                The 7 Habits of Highly Effective People
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2T0fuV7" target="_blank" rel="noreferrer noopener">
                The Happiness Advantage
              </a>
            </li>
          </ul>
        </div>

        <div className="grid__col--xs-12 grid__col--sm-6 text-center">
          <h2>Books - Personal Development, Leadership &amp; Business</h2>

          <ul className="resource-list">
            <li className="resource-list__elem">
              <a href="https://amzn.to/2TUgPwH" target="_blank" rel="noreferrer noopener">
                The 4-Hour Work Week
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2szfaRs" target="_blank" rel="noreferrer noopener">
                Tools of Titans
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2DikPSp" target="_blank" rel="noreferrer noopener">
                Smarter, Faster, Better
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2SVR92F" target="_blank" rel="noreferrer noopener">
                The One Thing
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2T09POV" target="_blank" rel="noreferrer noopener">
                High Performance Habits
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2CwvW8G" target="_blank" rel="noreferrer noopener">
                Perennial Seller
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2sxej3G" target="_blank" rel="noreferrer noopener">
                Never Split the Difference
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2T2eJe0" target="_blank" rel="noreferrer noopener">
                Man Up
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2FG3Fzq" target="_blank" rel="noreferrer noopener">
                Extreme Ownership
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2TWpWNy" target="_blank" rel="noreferrer noopener">
                The Dichotomy of Leadership
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2MjesRi" target="_blank" rel="noreferrer noopener">
                Leaders Eat Last
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2T0S0iL" target="_blank" rel="noreferrer noopener">
                Playing to Win
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2T2P8BY" target="_blank" rel="noreferrer noopener">
                You Are a Badass at Making Money
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2W2KC8b" target="_blank" rel="noreferrer noopener">
                Stealing Fire
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2W3o9rw" target="_blank" rel="noreferrer noopener">
                Crushing It
              </a>
            </li>
          </ul>
        </div>

        <div className="grid__col--xs-12 grid__col--sm-6 text-center">
          <h2>Books - Fitness, Nutrition &amp; Wellness</h2>

          <ul className="resource-list">
            <li className="resource-list__elem">
              <a href="https://amzn.to/2CrM3Ej" target="_blank" rel="noreferrer noopener">
                Sleep Smarter
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2sA2uKh" target="_blank" rel="noreferrer noopener">
                What to Eat
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2SVMKN8" target="_blank" rel="noreferrer noopener">
                Genius Foods
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2SYLeK7" target="_blank" rel="noreferrer noopener">
                Food: What the Heck Should I Eat?
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2sAIarW" target="_blank" rel="noreferrer noopener">
                Own the Day
              </a>
            </li>

            <li className="resource-list__elem">
              <a href="https://amzn.to/2sxiRHB" target="_blank" rel="noreferrer noopener">
                The End of Illness
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
);

export default ResourcesPage;
